import {Action, Events, ecomAppDefID, currencyConverterAppDefID} from '../constants';
import {DependantApps} from '../services/DependantApps';
import {doTransaction} from '../transaction';

export function handleOnEvent(
  {eventType, eventPayload}: {eventType: Events; eventPayload: any},
  sdk: IEditorSdk,
  dependantApps: DependantApps
) {
  switch (eventType) {
    case Events.manageStores:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Events.deletePage: {
      const {pageRef} = eventPayload;
      return doTransaction(sdk, () => sdk.pages.remove('', {pageRef}));
    }
    case Events.dashboardClosed:
      return doTransaction(sdk, () => dependantApps.installMySubscriptionsPageInMembersAreaIfNeeded());
    case Events.appActionClicked:
      return handleAppsManagerActions(sdk, eventPayload.actionId);
  }
}

function handleAppsManagerActions(sdk: IEditorSdk, action: Action) {
  switch (action) {
    case Action.openDashboard:
      return sdk.editor.openDashboardPanel('', {url: '', closeOtherPanels: false});
    case Action.openManageProducts:
      return sdk.editor.openDashboardPanel('', {url: 'store/products', closeOtherPanels: false});
    case Action.openAddPanel:
      return sdk.editor.deeplink.show('', {type: 'addPanel', params: [ecomAppDefID]});
    case Action.openPagesPanel:
      return sdk.editor.deeplink.show('', {type: 'pagesPanel', params: [ecomAppDefID]});
    case Action.findProductsToSell:
      return sdk.editor.openDashboardPanel('', {url: 'store/explore-products', closeOtherPanels: false});
    case Action.addRelatedApps:
      return sdk.editor.deeplink.show('', {type: 'addPanel', params: [currencyConverterAppDefID]});
  }
}
